var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "systems", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        cols: "",
                        lg: "8",
                        md: "12",
                        sm: "12",
                        align: "center",
                      },
                    },
                    [
                      _vm.teamWeeks
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row row justify-content-center content",
                            },
                            [
                              _vm._l(_vm.teamWeeks, function (tw, index) {
                                return _c(
                                  "b-container",
                                  {
                                    key: `sub-content-${index}`,
                                    staticClass:
                                      "col-lg-5 col-md-12 col-sm-12 sub-content",
                                    class: `sub-content-${tw.status_label
                                      .toLowerCase()
                                      .trim()
                                      .replace(/\s/g, "")}`,
                                    on: {
                                      click: function ($event) {
                                        return _vm.openModal(tw)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "position-relative img-content",
                                      },
                                      [
                                        _c("img", { attrs: { src: tw.img } }),
                                        _vm._v(" "),
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass:
                                              "position-absolute badge-top-right badge",
                                            class: `badge-${tw.status_label
                                              .toLowerCase()
                                              .trim()}`,
                                            attrs: { pill: "" },
                                          },
                                          [_vm._v(_vm._s(tw.status_label))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.currentUser
                                      ? _c(
                                          "h1",
                                          {
                                            staticClass: "nav-link active mb-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.currentUser.team) +
                                                " Team " +
                                                _vm._s(tw.week_name) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm.modalData
                                ? _c(
                                    "b-modal",
                                    {
                                      ref: "modalbasic",
                                      attrs: {
                                        id: "modalbasic",
                                        "hide-header": "",
                                        "hide-footer": "",
                                      },
                                      model: {
                                        value: _vm.showModal,
                                        callback: function ($$v) {
                                          _vm.showModal = $$v
                                        },
                                        expression: "showModal",
                                      },
                                    },
                                    [
                                      _vm.modalData.status_label
                                        .toLowerCase()
                                        .trim() === "open"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row row justify-content-center",
                                              attrs: { align: "center" },
                                            },
                                            [
                                              _vm.currentTeamWeek
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "mb-5" },
                                                        [
                                                          _vm.currentUser
                                                            ? _c(
                                                                "h1",
                                                                {
                                                                  staticClass:
                                                                    "text-primary m-0 p-0 align-middle",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .currentTeamWeek
                                                                          .open_day_name
                                                                      ) +
                                                                      "\n                      " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .modalData
                                                                          .week_name
                                                                      ) +
                                                                      "\n                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.currentUser
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "avatar ml-2 align-middle",
                                                                style: {
                                                                  backgroundImage:
                                                                    "url(" +
                                                                    _vm
                                                                      .currentUser
                                                                      .teamImg +
                                                                    ")",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 mb-5",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm.modalData
                                                                .img,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row row justify-content-center mb-1",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-lg-5 col-md-5 col-sm-6 col-xs-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "danger",
                                                                        to: "/work/lodgements",
                                                                        target:
                                                                          "_blank",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Lodgement System"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-lg-5 col-md-5 col-sm-6 col-xs-6",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "light",
                                                                        to: "/work/allocation",
                                                                        target:
                                                                          "_blank",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Work Allocation"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticClass:
                                                            "nav-link active mb-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "No open day yet"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 mb-5",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: "assets/img/svg/digital_nomad.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.modalData.status_label
                                        .toLowerCase()
                                        .trim() === "complete"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row row justify-content-center",
                                              attrs: { align: "center" },
                                            },
                                            [
                                              _vm.currentUser
                                                ? _c(
                                                    "h1",
                                                    {
                                                      staticClass:
                                                        "nav-link active mb-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.modalData
                                                              .week_name
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-5" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.modalData.img,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-10 mt-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "success",
                                                            to: "/vmb",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Visual Management Board"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }