<template>
  <b-card class="systems" no-body>
    <b-card-body>
      <b-container>
        <b-row align-h="center">
          <b-col cols lg="8" md="12" sm="12" align="center">
            <div class="d-flex flex-row row justify-content-center content" v-if="teamWeeks">
              <b-container
                class="col-lg-5 col-md-12 col-sm-12 sub-content"
                v-for="(tw, index) in teamWeeks"
                :class="`sub-content-${tw.status_label.toLowerCase().trim().replace(/\s/g, '')}`"
                :key="`sub-content-${index}`"
                @click="openModal(tw)"
              >
                <div class="position-relative img-content">
                  <img :src="tw.img" />
                  <b-badge
                    pill
                    class="position-absolute badge-top-right badge"
                    :class="`badge-${tw.status_label.toLowerCase().trim()}`"
                    >{{ tw.status_label }}</b-badge
                  >
                </div>
                <h1 v-if="currentUser" class="nav-link active mb-1">
                  {{ currentUser.team }} Team {{ tw.week_name }}
                </h1>
              </b-container>

              <b-modal
                id="modalbasic"
                ref="modalbasic"
                v-model="showModal"
                v-if="modalData"
                hide-header
                hide-footer
              >
                <div
                  v-if="modalData.status_label.toLowerCase().trim() === 'open'"
                  class="d-flex flex-row row justify-content-center"
                  align="center"
                >
                  <div class="col-12" v-if="currentTeamWeek">
                    <div class="mb-5">
                      <h1 v-if="currentUser" class="text-primary m-0 p-0 align-middle">
                        {{ currentTeamWeek.open_day_name }}
                        {{ modalData.week_name }}
                      </h1>
                      <span
                        v-if="currentUser"
                        class="avatar ml-2 align-middle"
                        :style="{
                          backgroundImage: 'url(' + currentUser.teamImg + ')'
                        }"
                      ></span>
                    </div>
                    <div class="col-12 mb-5">
                      <img :src="modalData.img" />
                    </div>
                    <div class="col-12">
                      <div class="d-flex flex-row row justify-content-center mb-1">
                        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6">
                          <b-button variant="danger" to="/work/lodgements" target="_blank"
                            >Lodgement System</b-button
                          >
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6">
                          <b-button variant="light" to="/work/allocation" target="_blank"
                            >Work Allocation</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-else>
                    <h1 class="nav-link active mb-4">No open day yet</h1>
                    <div class="col-12 mb-5">
                      <img src="assets/img/svg/digital_nomad.svg" alt />
                    </div>
                  </div>
                </div>
                <div
                  v-if="modalData.status_label.toLowerCase().trim() === 'complete'"
                  class="d-flex flex-row row justify-content-center"
                  align="center"
                >
                  <h1 v-if="currentUser" class="nav-link active mb-4">
                    {{ modalData.week_name }}
                  </h1>
                  <div class="col-12 mb-5">
                    <img :src="modalData.img" />
                  </div>
                  <div class="col-12">
                    <div class="col-10 mt-3">
                      <b-button variant="success" to="/vmb">Visual Management Board</b-button>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'

export default {
  data() {
    return {
      showModal: false,
      modalData: null,
      timer: ''
    }
  },
  mixins: [dataPreloadMixin],
  computed: {
    ...mapGetters(['currentUser', 'teamWeeks', 'currentTeamWeek'])
  },
  methods: {
    hideModal(refname) {
      console.log(refname)
      console.log(this.$refs[refname])
      this.$refs[refname].hide()
      console.log('hide modal:: ' + refname)
    },
    openModal(data) {
      let id = data.id
      let status = data.status_label.toLowerCase().trim()
      if (status === 'complete' && id === 1) {
        this.showModal = false
        return
      }
      if (status === 'not assigned' || status === 'waiting') {
        this.showModal = false
      } else {
        this.showModal = true
        this.modalData = data
      }
      this.modalData = data
    },
    async fetchTeamWeeks() {
      if (this.currentUser === null) {
        await this.resetState()
      } else {
        await this.getTeamWeeks()
      }
    }
  },
  mounted() {
    /* popover manuel close */
    this.$root.$on('bv::popover::show', () => {
      this.$root.$emit('bv::hide::popover')
    })
    document.addEventListener('click', (e) => {
      if (e.target.id.indexOf('pop') === -1) {
        this.$root.$emit('bv::hide::popover')
      }
    })
  },
  created() {
    this.fetchTeamWeeks()
    this.timer = setInterval(this.fetchTeamWeeks, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
